
import Vue from 'vue'

export default Vue.extend({
  name: 'Abmelden',

  data: () => {
    return {
      dots: '.',
      interv: 0,
    };
  },
  
  computed: {
    darkMode () { 
      return this.$store.state.darkMode
    },
    pressMode () { 
      return this.$store.state.pressMode
    },
  },

  methods: {
    animateDots () {
      if (this.dots.length <= 4) {
        this.dots += '.'
      } else {
        this.dots = '.'
      }
    },
    async logout () {
      const yes = confirm("Sind Sie sich sicher?")
      if (yes) {
        this.$store.commit('setUser', null)
        if (this.darkMode) {
          this.$router.push('/auth')
        } else if (this.pressMode) {
          this.$router.push('/presse')
        } else {
          this.$router.push('/')
        }
        console.log('logout: ')
        // TODO: logout in API?
      } else {
        this.$router.go(-1)
      }
    },
  },

  mounted () {
    console.log('Abmelden mounted');
    this.interv = window.setInterval(this.animateDots, 200)
    setTimeout(this.logout, 800)
  },

  destroyed () {
    clearInterval(this.interv)
  },
});
